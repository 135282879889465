<template>
  <div class="allCategories">
    <div class="header-search">
      <div class="search-l" @click="isShowSelect = !isShowSelect">
        <img class="location" src="./img/location.png" alt="" />
        <span class="text">{{ selectText }}</span>
        <img class="arrow" src="./img/down-arrow.png" alt="" />
      </div>
      <transition name="fade-move">
        <div class="select" v-if="isShowSelect">
          <ul>
            <li
              v-for="(item, index) in sortType"
              :key="index"
              :class="{ active: curIndex == index }"
              @click="handelSelectStreet(item, index)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </transition>

      <div class="search-r">
        <v-input
          leftIcon="search"
          placeholder="请输入关键字"
          v-model="params.title"
          @blur="handelBlurTitle"
        >
        </v-input>
      </div>
    </div>
    <div class="section">
      <div class="section-l">
        <div
          :class="['nav', { active: currentIndex == index }]"
          v-for="(item, index) in severTypeList"
          :key="index"
          @click="handelSelectServeType(item, index)"
        >
          {{ item.dictValue }}
        </div>
      </div>
      <div class="section-r">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          :finishedText="finishedText"
          ref="list"
        >
          <div
            class="content"
            @click="toDetailPage(item.id)"
            v-for="(item, index) in serveList"
            :key="index"
          >
            <div class="detail-r-title">
              {{ item.title }}
            </div>
            <div class="detail-r-describe">
              {{ item.subTitle }}
            </div>
            <div class="detail-r-evaluate">
              <div
                class="detail-r-evaluate-status"
                v-if="item.evaluateScore == 0"
              >
                未评价
              </div>
              <div class="rate" v-else>
                <rate :rate="item.evaluateScore"></rate>
              </div>
            </div>
            <div class="detail-r-serve-detail">
              <div class="time">{{ checkTime(item.createTime) }}</div>
              <div class="btn">{{ item.serviceTypeLv2Name }}</div>
            </div>
          </div>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import rate from "./rating.vue";
import { mapState } from "vuex";
import {
  getServiceList,
  getInitInfo,
  getServiceTypeLv2,
  initWxJsSdkUrl,
} from "./api.js";
export default {
  name: "allCategories",
  components: {
    rate,
  },
  props: {},
  data() {
    return {
      selectText: "全部",
      curIndex: -1,
      searchValue: "",
      finishedText: "没有更多了",
      finished: false,
      currentIndex: 0,
      isShowSelect: false,
      severTypeList: [], //服务类型列表
      sortType: [
        {
          title: "全部",
          value: "",
        },
        {
          title: "评分最高",
          value: 1,
        },
        {
          title: "距离最近",
          value: 2,
        },
        {
          title: "最新发布",
          value: 3,
        },
      ],
      params: {
        curPage: 1,
        pageSize: 10,
        serviceType: "",
        serviceTypeLv2: "",
        lat: "",
        lng: "",
        sortType: "", //排序方式
        title: "", //标题
      },
      serveList: [],
      streetList: [], //街道数据
      longitude: "",
      latitude: "",
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  async created() {
    const { code } = this.$route.query;
    if (code != undefined) {
      this.params.serviceType = code;
      await this.getInitInfo(code);
    }
    await this.initWxConfig();
    await this.getLocation();
  },
  mounted() {},
  methods: {
    // 微信授权H5可调用的 wx API功能
    async initWxConfig() {
      let ourl;
      if (process.env.NODE_ENV == "production") {
        ourl = location.href.split("#")[0];
      } else {
        ourl = origin;
      }
      let params = {
        url: ourl,
      };
      let url = initWxJsSdkUrl;
      let res = await this.$axios.post(`${url}`, this.$qs.stringify(params));
      if (res.code == 200) {
        this.wxData = res.data;
        console.log("this.wxData ----->", this.wxData);
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: this.wxData.appId, // 必填，公众号的唯一标识
          timestamp: this.wxData.timestamp, // 必填，生成签名的时间戳
          nonceStr: this.wxData.nonceStr, // 必填，生成签名的随机串
          signature: this.wxData.signature, // 必填，签名
          jsApiList: ["checkJsApi", "getLocation"],
        });
      }
    },
    getLocation() {
      let _this = this;
      wx.ready(() => {
        console.log(" wx.ready----->");
        wx.checkJsApi({
          jsApiList: ["getLocation"],
          success: (rest) => {
            //获取当前位置
            wx.getLocation({
              type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
              success: function (res) {
                console.log("res----->", res);
                _this.latitude = res.latitude;
                _this.longitude = res.longitude;
              },
            });
          },
          fail: (res) => {
            console.log("res----->", res);
          },
        });
      });
      wx.error((error) => {
        console.log("error----->", error);
      });
    },
    checkTime(time) {
      const currentTime = new Date();
      const fixedTime = new Date(time);
      const timeDifference = currentTime - fixedTime;
      const minutesDifference = Math.floor(timeDifference / 60000);
      // 判断当前时间是否在十五分钟前
      if (minutesDifference <= 15 && minutesDifference >= -15) {
        return "刚刚";
      } else {
        // 格式化固定时间
        // const formattedTime = fixedTime.toLocaleString(); // 格式为默认的日期时间格式
        return time;
      }
    },
    initCurpage() {
      this.params.curPage = 1;
    },
    handelBlurTitle() {
      this.initCurpage();
      this.getServiceList();
    },
    handelSelectStreet(item, index) {
      if (item.value === 2) {
        this.params.lat = this.latitude;
        this.params.lng = this.longitude;
      } else {
        this.params.lat = "";
        this.params.lng = "";
      }
      this.params.sortType = item.value;
      this.curIndex = index;
      this.selectText = item.title;
      this.isShowSelect = false;
      this.initCurpage();
      this.getServiceList();
    },
    handelSelectServeType(item, index) {
      console.log("item----->", item);
      this.currentIndex = index;
      this.params.serviceTypeLv2 = item.dictKey;
      this.initCurpage();
      this.getServiceList();
    },
    onLoad() {
      if (this.params.serviceTypeLv2) {
        // this.getServiceList();
        this.getServiceList();
      }
    },
    //获取发布列表页面
    getServiceList(serviceType) {
      let params = {
        houseId: this.houseId || this.communityId,
        ...this.params,
      };
      this.$axios
        .get(
          `${getServiceList}`,
          { params },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.code === 200) {
            if (this.params.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.serveList = [];
              this.finishedText = "";
              return;
            }
            if (this.params.curPage === 1) {
              this.serveList = res.data.records;
            } else {
              const list = this.serveList.map((v) => v.id);
              res.data.records.forEach((item) => {
                // 如果请求数据不重复，则添加进listData
                list.includes(item.id) || this.serveList.push(item);
              });
            }
            this.serveList.forEach((item) => {
              if (item.coverPicture && item.coverPicture.length > 0) {
                item.coverPicture = this.$handleImg(
                  224,
                  220,
                  item.coverPicture
                );
              }
            });
            this.params.curPage++;
          }
        });
    },
    //获取服务类别数据
    async getInitInfo(code) {
      // const code = 'convenience_service_type';
      const res = await this.$axios.get(`${getServiceTypeLv2}?code=${code}`);
      console.log("res----->", res);
      if (res.code == 200) {
        const { list } = res.data;
        this.severTypeList = list;
        this.params.serviceTypeLv2 = this.severTypeList[0].dictKey;
        this.onLoad();
      }
    },
    toDetailPage(id) {
      this.$router.push({
        name: "convenientServiceDetail",
        query: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.allCategories {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px 30px 0 30px;
  width: 100%;
  height: 100%;
  padding-bottom: calc(68px + constant(safe-area-inset-bottom));
  padding-bottom: calc(68px + env(safe-area-inset-bottom));
  .header-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    box-sizing: border-box;
    .select {
      z-index: 999;
      position: absolute;
      top: 90px;
      width: 206px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
      border-radius: 16px;
      left: 30px;
      ul {
        position: relative;
        padding: 26px 0 24px 0;
        // &::before {
        //   content: "";
        //   width: 0;
        //   height: 0;
        //   border-left: 20px solid transparent;
        //   border-right: 20px solid transparent;
        //   border-bottom: 20px solid #fff;
        //   position: absolute;
        //   left: 50%;
        //   transform: translateX(-50%);
        //   top: -11px;
        // }
      }
      li {
        text-align: center;

        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 40px;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        &.active {
          color: #5390f9;
        }
      }
    }
    .fade-move-leave-active,
    .fade-move-enter-active {
      transition: all 0.15s;
    }
    .fade-move-enter {
      opacity: 1;
      transform: translateY(-10px);
    }
    .fade-move-leave-to {
      opacity: 0;
      transform: translateY(-10px);
    }

    .search-l {
      width: 240px;
      display: flex;
      align-items: center;
      .location {
        width: 40px;
        height: 40px;
      }
      .text {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        margin: 0 24px 0 14px;
      }
      .arrow {
        width: 14px;
        height: 10px;
      }
    }
    .search-r {
      flex: 1;
      max-width: calc(100% - 240px);
      height: 62px;
      overflow: hidden;
      > div {
        width: 100%;
        height: 100%;
        ::v-deep .van-cell {
          overflow: hidden;
          border-radius: 31px;
          background: #f7f7f7;
          width: 100%;
          height: 100%;
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 40px;
          padding: 0;
          padding-left: 44px;
          display: flex;
          align-items: center;
          .van-field__left-icon {
            margin-right: 4px;
            margin-top: 6px;
            .van-icon {
              font-size: 28px;
            }
          }
        }
        ::v-deep .van-field__control::-webkit-input-placeholder {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 40px;
        }
      }
    }
  }
  .section {
    width: 100%;
    flex: 1;
    max-height: calc(100% - 64px);
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .section-l {
      padding-top: 2px;
      width: 220px;
      overflow-y: scroll;
      height: 100%;
      background: #f7f7f7;
      border-radius: 0px 18px 16px 16px;
      margin-right: 20px;
      .nav {
        width: 100%;
        box-sizing: border-box;
        text-align: center;
        padding: 40px 20px 40px 20px;
        font-size: 28px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 40px;
        position: relative;

        &.active {
          position: relative;
          color: #4864b0;
          background: #ffffff;
          &::before {
            content: "";
            width: 6px;
            height: 50%;
            background: #4864b0;
            border-radius: 4px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
          }
        }
      }
    }
    .section-r {
      position: relative;
      overflow-y: scroll;
      flex: 1;
      height: 100%;
      padding: 2px 3px;
      max-width: calc(100% - 220px);
      &::-webkit-scrollbar {
        display: none;
      }
      .content {
        position: relative;
        height: 220px;
        background: #ffffff;
        box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.5);
        border-radius: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 24px 0 0 28px;

        &:not(:last-child) {
          margin-bottom: 24px;
        }
        .detail-r-title {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
        }
        .detail-r-describe {
          padding-right: 40px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 8px 0 14px 0;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
        .detail-r-evaluate {
          .detail-r-evaluate-status {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 38px;
            border-radius: 19px;
            border: 2px solid #ffd343;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffd343;
            line-height: 34px;
          }
          .rate {
            width: 206px;
            overflow: hidden;
            position: relative;
            height: 32px;
          }
        }
        .detail-r-serve-detail {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .time {
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c2c2c2;
            line-height: 34px;
            white-space: nowrap;
            margin-right: 10px;
          }
          .btn {
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 194px;
            height: 62px;
            background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
            box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
            border-radius: 16px 0px 16px 0px;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 62px;
          }
        }
      }
    }
  }
}
</style>
